import CreateApplicationDialog from "@/components/applications/application/CreateApplicationDialog.vue";
import EditApplicationDialog from "@/components/applications/application/EditApplicationDialog.vue";
import DeleteApplicationDialog from "@/components/applications/application/DeleteApplicationDialog.vue";

export default {
  name: "Applications",

  data: () => ({
    search: "",
    headers: [
      {
        text: "Nombre",
        value: "name",
        align: "start",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Descripción",
        value: "description",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "URL",
        value: "url",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Estado",
        value: "active",
        align: "center",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "right",
        sortable: false,
        class: "primary--text",
      },
    ],
    editedItem: {},
    deletedItem: {},
    currentPage: 1,
    timeout: null,
    limit: 5,
    rowsPerPage: [{ value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }]
  }),

  computed: {
    applications() {
      return this.$store.getters["applications/getApplications"];
    },
    paginationPages() {
      return this.$store.getters["applications/getPaginationPages"];
    },
    isLoading() {
      return this.$store.state.applications.isLoading;
    },
  },

  watch: {
    search(val) {
      this.listenSearch(val);
    },
  },

  created() {
    this.$store.dispatch("applications/getApplications", { page: 1 });
    this.$store.dispatch("applications/updateCurrentPage", 1);
  },

  methods: {
    editItem(item) {
      this.editedItem = { ...item };
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
    },

    listenSearch(val) {
      if (val.length > 3 || val == "") {
        this.appSelected = null;
        this.currentPage = 1;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$store.dispatch("applications/getApplications", {
            page: 1,
            search: this.search,
          });
        }, 1000);
      }
    },

    listenPagination() {
      this.$store.dispatch("applications/getApplications", {
        page: this.currentPage,
        search: this.search,
        limit: this.limit
      });
      this.$store.dispatch("applications/updateCurrentPage", this.currentPage);
    },

    resetFilter() {
      this.search = "";
      this.currentPage = 1;
    },
  },
  components: {
    CreateApplicationDialog,
    EditApplicationDialog,
    DeleteApplicationDialog,
  },
};
